import React, { useEffect, useState } from 'react';
import { isMobile } from '@/biz/tool';
import toast from 'react-hot-toast';
import { Tracker } from '@/utils';
import InvitesCard from '../components/invites/InvitesCard';
import TodosCard from '../components/todos/TodosCard';
import CreatorStepsCard from './CreatorStepsCard';
import ResourceItemCard from './ResourceItemCard';
import Banner from './Banner';
import FirstOrderBanner from './FirstOrderBanner';
import { creatorStepsItems, resourcesItems, ActionsStatusEnum } from './config';
import axiosInstance from '../../../apis/axiosInstance';
import { hasClerkUser, getAhaSourceFromUrlSearch } from '@/biz/tool';
import { useNavigate } from 'react-router-dom';
import LoginModal from '@/components/common/LoginModal';
import ClerkLoginModalCantClose from '@/components/common/ClerkLoginModalCantClose';
import Notices from '@/components/common/Notices';
import { NoticePaddingIcon } from '@/components/common/icon';
import { useLoginStore } from '@/hooks/useLoginStore';
import { usePlatformStore } from '@/hooks/usePlatformStore';
import styles from './homeContent.module.less';

const HomeContent: React.FC = () => {
  const MAX_NUM = 3;
  const { channelName } = useLoginStore();
  // 获取达人信息
  const { influencers, user } = usePlatformStore();
  const [invitesList, setInvites] = useState([]);
  const [invitesTotal, setInvitesTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isWaveHovered, setIsWaveHovered] = useState(false);
  const [actionsList, setActions] = useState([]);
  // 获取所有订单
  const getOrders = async () => {
    setIsLoading(true);
    try {
      const { data } = await axiosInstance.get('/influencer/order');
      const { actions = [], invites = [] } = data?.data || {};
      // 处理邀请
      const invite = invites?.length > 3 ? invites.slice(0, 3) : invites;
      const invitesList = invite?.filter((item: any) => !item?.notInterested);
      // 处理 actions
      const action = actions?.length > 3 ? actions.slice(0, 3) : actions;
      const actionsList = action?.filter((item: any) =>
        [
          ActionsStatusEnum.UnderScriptProduction,
          ActionsStatusEnum.UnderVideoProduction,
          ActionsStatusEnum.PendingPublish,
          ActionsStatusEnum.PendingCreatorAddress
        ].includes(item?.displayStatus)
      );
      setActions(actionsList);
      setInvites(invitesList);
      setInvitesTotal(invites?.length);
    } catch (error) {
      setActions([]);
      setInvites([]);
      toast.error(`${error}`);
    } finally {
      setIsLoading(false);
    }
  };

  const [isShowLoginModal, setLoginModalVisible] = useState(false);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);

    try {
      Tracker.gtag('event', 'screen_view', {
        event_category: 'platform_influencer',
        utm_source: query.get('utm_source') || 'none',
        utm_medium: query.get('utm_medium') || 'none',
        value: 1
      });
      const ahaSource = getAhaSourceFromUrlSearch();
      if (ahaSource) {
        Tracker.gtag('event', 'screen_view', {
          event_category: ahaSource, // 指标：官网曝光 from webflow
          value: 9527
        });
      }
    } catch (error) {
      console.log(error);
    }

    const showLoginModal = query.get('showLoginModal') || false;
    if (showLoginModal) {
      setLoginModalVisible(true);
    }

    if (!hasClerkUser()) {
      return;
    }
    getOrders();
  }, []);

  const navigate = useNavigate();
  const handleInvites = () => {
    navigate(`/campaign/invites`);
  };

  return (
    <div
      className={`${isMobile() ? styles.mobileContainer : ''} ${styles.container}`}
    >
      <Banner />
      {channelName ? (
        <div className={styles.title}>
          Welcome back, {channelName}
          <span
            className="ml-2"
            onMouseEnter={() => setIsWaveHovered(true)}
            onMouseLeave={() => setIsWaveHovered(false)}
          >
            {isWaveHovered ? (
              <img
                className={'w-[30px] h-[30px] mt-1'}
                src="/wave.gif"
                alt="hello"
              />
            ) : (
              <span>👋</span>
            )}
          </span>
        </div>
      ) : (
        <div className={styles.title}>Welcome!</div>
      )}
      {!invitesList && !actionsList && (
        <div className={styles.notices}>
          <Notices
            Icon={NoticePaddingIcon}
            title="Finding you the perfect deal..."
            desc="Our algorithm is searching daily for your best brand matches. Stay tuned via email for new invites!"
          />
        </div>
      )}

      {/* invites */}
      {!!invitesList?.length && (
        <div className={styles.invites}>
          <div className={styles.invitesHead}>
            <div className={styles.headTitle}>New invites</div>
            {invitesTotal > MAX_NUM && (
              <div className={styles.all} onClick={handleInvites}>
                See all ({invitesTotal})
              </div>
            )}
          </div>
          {!!user?.duringFirstOrder && (
            <FirstOrderBanner handleClick={handleInvites} userId={user?._id} />
          )}
          <div
            className={`${styles.invitesContent} ${invitesList?.length < MAX_NUM ? styles.invitesContentLess : ''}`}
          >
            {invitesList.map((item: any, index) => (
              <InvitesCard
                key={item?._id}
                item={item}
                index={index}
                isOnlyOne={invitesList?.length === 1}
              />
            ))}
          </div>
        </div>
      )}
      {/* to dos */}
      {!!actionsList?.length && (
        <div className={styles.toDos}>
          <div className={styles.toDosHead}>
            <div className={styles.headTitle}>To-dos</div>
          </div>
          <div
            className={`${styles.toDosContent} ${actionsList?.length < MAX_NUM ? styles.toDosContentLess : ''}`}
          >
            {actionsList.map((item: any, index) => (
              <TodosCard key={item?._id} item={item} />
            ))}
          </div>
        </div>
      )}
      {/* 骨架屏加载动画 */}
      {isLoading && !isMobile() ? (
        <div className="flex flex-col gap-2 w-full px-20 mb-10 text-xl text-[#1C2024] font-semibold font-['Geist'] leading-loose">
          New invites
          <div className="flex gap-8 mb-[60px]">
            <div className="h-[262px] w-[32.2%] bg-gray-200 rounded-2xl animate-pulse" />
            <div className="h-[262px] w-[32.2%] bg-gray-200 rounded-2xl animate-pulse" />
          </div>
          To-dos
          <div className="flex gap-8">
            <div className="h-[144px] w-[33%] bg-gray-200 rounded-2xl animate-pulse" />
            <div className="h-[144px] w-[33%] bg-gray-200 rounded-2xl animate-pulse" />
            <div className="h-[144px] w-[33%] bg-gray-200 rounded-2xl animate-pulse" />
          </div>
        </div>
      ) : null}
      {/* 骨架屏加载动画 */}
      {isLoading && isMobile() ? (
        <div className="flex flex-col gap-2 w-full px-4 mb-10 text-xl text-[#1C2024] font-semibold font-['Geist'] leading-loose">
          New invites
          <div className="flex flex-col gap-8 mb-[60px]">
            <div className="h-[262px] w-full bg-gray-200 rounded-2xl animate-pulse" />
          </div>
          To-dos
          <div className="flex flex-col gap-8">
            <div className="h-[144px] w-full bg-gray-200 rounded-2xl animate-pulse" />
            <div className="h-[144px] w-full bg-gray-200 rounded-2xl animate-pulse" />
            <div className="h-[144px] w-full bg-gray-200 rounded-2xl animate-pulse" />
          </div>
        </div>
      ) : null}

      {/* 底部静态区域 */}
      <div className={styles.bottomContainer}>
        <div className={styles.creatorSteps}>
          <div className={styles.stepsHeader}>
            Become a top-earning creator in 5 steps
          </div>
          <div className={styles.stepsContent}>
            {creatorStepsItems.map((item, index) => (
              <CreatorStepsCard
                key={item?.title}
                item={item}
                influencers={influencers || []}
                index={index}
              />
            ))}
          </div>
        </div>
        <div className={styles.resources}>
          <div className={styles.resourceTitle}>Resources</div>
          <div className={styles.resourceContent}>
            {resourcesItems.map((item) => (
              <ResourceItemCard key={item?.title} item={item} />
            ))}
          </div>
        </div>
      </div>
      {/* <LoginModal visible={isShowLoginModal} /> */}
      <ClerkLoginModalCantClose />
    </div>
  );
};

export default HomeContent;
