import { create } from 'zustand';

type WebModalStore = {
  showNotInterestSubmitModal: boolean;
  setShowNotInterestSubmitModal: (showNotInterestSubmitModal: boolean) => void;
  showFlowGuideModal: boolean;
  setShowFlowGuideModal: (showFlowGuideModal: boolean) => void;
  showSelectPlatformModal: boolean;
  setShowSelectPlatformModal: (showSelectPlatformModal: boolean) => void;
  showContactInformationModal: boolean;
  setShowContactInformationModal: (
    showContactInformationModal: boolean
  ) => void;
  showSelectInvitedOrderModal: boolean;
  setShowSelectInvitedOrderModal: (
    showSelectInvitedOrderModal: boolean
  ) => void;
  showModalJoinSuccess: boolean;
  setShowModalJoinSuccess: (showModalJoinSuccess: boolean) => void;
  showModalMissOut: boolean;
  setShowModalMissOut: (showModalMissOut: boolean) => void;
  showModalAnalysis: boolean;
  setShowModalAnalysis: (showModalAnalysis: boolean) => void;
  showModalSelectInvitedOrderByNoMatch: boolean;
  setShowModalSelectInvitedOrderByNoMatch: (
    showModalSelectInvitedOrderByNoMatch: boolean
  ) => void;
  showModalSelectInvitedOrderByNoMatchType: 'success' | 'noMatch';
  setShowModalSelectInvitedOrderByNoMatchType: (
    showModalSelectInvitedOrderByNoMatchType: 'success' | 'noMatch'
  ) => void;
  showModalConfirmDealPrice: boolean;
  setShowModalConfirmDealPrice: (showModalConfirmDealPrice: boolean) => void;
  showModalSubmitInterest: boolean;
  setShowModalSubmitInterest: (showModalSubmitInterest: boolean) => void;
  skipScript: boolean;
  setSkipScript: (skipScript: boolean) => void;
  isAgree: boolean;
  setIsAgree: (isAgree: boolean) => void;
  isShowAgreement: boolean;
  setIsShowAgreement: (isShowAgreement: boolean) => void;
  isShowAddressModal: boolean;
  setShowAddressModal: (isShowAddressModal: boolean) => void;
};

export const useWebModalStore = create<WebModalStore>()((set) => ({
  showNotInterestSubmitModal: false,
  setShowNotInterestSubmitModal: (showNotInterestSubmitModal: boolean) =>
    set({ showNotInterestSubmitModal }),
  showFlowGuideModal: false,
  setShowFlowGuideModal: (showFlowGuideModal: boolean) =>
    set({ showFlowGuideModal }),
  showSelectPlatformModal: false,
  setShowSelectPlatformModal: (showSelectPlatformModal: boolean) =>
    set({ showSelectPlatformModal }),
  showContactInformationModal: false,
  setShowContactInformationModal: (showContactInformationModal: boolean) =>
    set({ showContactInformationModal }),
  showSelectInvitedOrderModal: false,
  setShowSelectInvitedOrderModal: (showSelectInvitedOrderModal: boolean) =>
    set({ showSelectInvitedOrderModal }),
  showModalJoinSuccess: false,
  setShowModalJoinSuccess: (showModalJoinSuccess: boolean) =>
    set({ showModalJoinSuccess }),
  showModalMissOut: false,
  setShowModalMissOut: (showModalMissOut: boolean) => set({ showModalMissOut }),
  showModalAnalysis: false,
  setShowModalAnalysis: (showModalAnalysis: boolean) =>
    set({ showModalAnalysis }),
  showModalSelectInvitedOrderByNoMatch: false,
  setShowModalSelectInvitedOrderByNoMatch: (
    showModalSelectInvitedOrderByNoMatch: boolean
  ) => set({ showModalSelectInvitedOrderByNoMatch }),
  showModalSelectInvitedOrderByNoMatchType: 'noMatch',
  setShowModalSelectInvitedOrderByNoMatchType: (
    showModalSelectInvitedOrderByNoMatchType: 'success' | 'noMatch'
  ) => set({ showModalSelectInvitedOrderByNoMatchType }),
  showModalConfirmDealPrice: false,
  setShowModalConfirmDealPrice: (showModalConfirmDealPrice: boolean) =>
    set({ showModalConfirmDealPrice }),
  showModalSubmitInterest: false,
  setShowModalSubmitInterest: (showModalSubmitInterest: boolean) =>
    set({ showModalSubmitInterest }),
  skipScript: false,
  setSkipScript: (skipScript: boolean) => set({ skipScript }),
  isAgree: false,
  setIsAgree: (isAgree: boolean) => set({ isAgree }),
  isShowAgreement: false,
  setIsShowAgreement: (isShowAgreement: boolean) => set({ isShowAgreement }),
  isShowAddressModal: false,
  setShowAddressModal: (isShowAddressModal: boolean) =>
    set({ isShowAddressModal })
}));
