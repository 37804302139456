export const enum SubmitStatusEnum {
  script = 'script',
  video = 'video',
  finalLink = 'finalLink',
  address = 'address'
}

export const submitTextMap: any = {
  [SubmitStatusEnum.script]: 'Submit script',
  [SubmitStatusEnum.video]: 'Submit content',
  [SubmitStatusEnum.finalLink]: 'Submit final link'
};

export const submitModalDescDefault: any = {
  [SubmitStatusEnum.script]: '',
  [SubmitStatusEnum.video]:
    'Please ensure the link is an google drive video or other video link.',
  [SubmitStatusEnum.finalLink]:
    "Please make sure you're uploading your approved final draft. To obtain your link, open your video on Platform > Copy the link in the URL bar."
};

export const submitModalDescYoutube: any = {
  [SubmitStatusEnum.script]:
    'Upload your content to Google Docs and submit the link here. Please ensure sharing is set to “Public.”',
  [SubmitStatusEnum.video]:
    'Please ensure the link is an unlisted YouTube video. (e.g. https://www.youtube.com/watch?v=3lnpTnjcH-k)',
  [SubmitStatusEnum.finalLink]:
    "Please make sure you're uploading your approved final draft. To obtain your link, open your video on YouTube > Copy the link in the URL bar."
};
export const submitModalDescTiktok: any = {
  [SubmitStatusEnum.script]: '',
  [SubmitStatusEnum.video]:
    'Please ensure the link is an google drive video or other video link.',
  [SubmitStatusEnum.finalLink]:
    "Please make sure you're uploading your approved final draft. To obtain your link, open your video on Tiktok > Copy the link in the URL bar."
};

export const submitModalDescInstagram: any = {
  [SubmitStatusEnum.script]: '',
  [SubmitStatusEnum.video]:
    'Please ensure the link is an google drive video or other video link.',
  [SubmitStatusEnum.finalLink]:
    "Please make sure you're uploading your approved final draft. To obtain your link, open your video on Instagram > Copy the link in the URL bar."
};

export const submitModalLinkTitle: any = {
  [SubmitStatusEnum.script]: 'Script draft link',
  [SubmitStatusEnum.video]: 'Content draft link',
  [SubmitStatusEnum.finalLink]: 'Final link'
};

// 0, 待提交 Draft
// 1, 等待审核
// 2, 等待发布
// 3, 已发布, 等待支付
// 4, 已支付
// 5 未知
export enum ProgressStatusEnum {
  SCRIPT = 0,
  DRAFT = 1,
  PUBLISH = 2,
  PAYMENT = 3,
  OTHER = 5 // 付款完成。
}

// post date enum
export enum PostTimeType {
  FLEXIBLE = 'flexible',
  FIXED = 'fixed'
}

export enum TabEnum {
  Delivery = 'Delivery',
  DealInfo = 'Deal info',
  Requirement = 'Requirement',
  AhaStudio = 'Aha studio'
}

export const DedicatedVideoContents: {
  title: string;
  content: any[];
  img?: string;
}[] = [
  {
    title: 'Idea submission',
    content: [
      'Please write a simple idea of how you plan to make your video. Once it’s approved, you can start creating it. This helps avoid changes or rework if the video doesn’t meet the requirements.'
    ]
  },
  {
    title: 'Video content',
    content: [
      'Once the application is approved, please check out the Aha studio as a reference before starting the video production process.'
    ],
    img: '/aha-studio.png'
  },
  {
    title: 'Video length',
    content: ['Ensure the overall video length is <b>at least 5 minutes</b>'],
    img: '/y-vl-five.png'
  },
  {
    title: 'CTA',
    content: [
      'Place the call-to-action at the most engaging point in the video, clearly stating something like, “Use the on-screen coupon code for exclusive offers” and “Check the link in the description for more details,” while displaying the coupon code if available. You can choose the CTA wording that resonates best with your audience. Driving conversions impacts future collaboration rates.'
    ],
    img: '/new_cta.png'
  },
  {
    title: 'Description & Video Title',
    content: [
      'Mention the brand name in the video title; if it’s not suitable for the title, ensure it is included in the video description.',
      {
        title:
          '<b>Use a strong call-to-action for promotion at the beginning of description</b> and as the <b>first pinned comment.</b> ',
        children: [
          'Ensure CTA and link are visible even when collapsed. e.g. Use code {coupon code} for XX% off. Try it now: {promotional link}'
        ]
      },
      '<b>Use the promotional link provided by Aha</b>, not the official website link or a redirected link.'
    ],
    img: '/y-des-link.png'
  },
  {
    title: '',
    content: [
      "<b> Do not tag(@) the official brand's accounts unless specified .</b> This approach helps focus audience attention on the most crucial promotional links.",
      'Except for the legally required sponsorship disclosure in the description or caption, do not include any additional language or indications (e.g., "#ad" or "advertisement") that explicitly label the content as an ad.'
    ],
    img: '/y-des-tag.png'
  },
  // {
  //   title: '',
  //   content: [
  //     'Avoid mentioning "paid partnership", “ad” or similar terms in the description.'
  //   ],
  //   img: '/y-des-avoid.png'
  // },
  {
    title: 'Timestamp',
    content: ['Create your timestamps.'],
    img: '/y-timestamp.png'
  },
  {
    title: 'Music and Effects',
    content: [
      'Check for copyright restrictions in your background music, sound effects, and clips.'
    ],
    img: ''
  }
];

export const IntegratedVideoContents: {
  title: string;
  content: any[];
  img?: string;
}[] = [
  {
    title: 'Content Restrictions',
    content: [
      'We recommend avoiding political commentary, explicit sexual content, graphic violence, sensitive social issues, or misleading information in recent content, as these can negatively impact all brand collaborations. '
    ],
    img: ''
  },
  {
    title: 'Idea submission',
    content: [
      'Please write a simple idea of how you plan to make your video. Once it’s approved, you can start creating it. This helps avoid changes or rework if the video doesn’t meet the requirements.'
    ]
  },
  {
    title: 'Video Content',
    content: [
      'Once the application is approved, please check out the Aha studio as a reference before starting the video production process.'
    ],
    img: '/aha-studio.png'
  },
  {
    title: '',
    content: [
      `During the video, make sure to mention, "Click the link in the description for more details" and "don't forget to use the on-screen coupon code for special offers" while displaying the coupon code in the video.`
    ]
  },
  {
    title: 'Video length',
    content: [
      'The promotional segment should constitute at least 30% of the total video duration to ensure the message is effectively conveyed while maintaining a balanced viewing experience.',
      "The promotional content should be seamlessly integrated into the video in a way that feels organic, authentic, and aligned with the creator's usual style and tone. Avoid overly scripted or forced messaging to ensure the promotion resonates naturally with the audience."
    ],
    img: '/60s.png'
  },
  {
    title: 'Placement',
    content: [
      'Ensure that the sponsored content about the product is presented<b> before reaching 50% of the video’s total duration.</b>'
    ],
    img: '/placement.png'
  },
  {
    title: 'Description',
    content: [
      {
        title:
          '<b>Use a strong call-to-action</b> for promotion at the<b> beginning of description</b> and as the <b>first pinned comment.</b>',
        children: [
          'Ensure CTA and link are visible even when collapsed.',
          'E.g. Use code {coupon code} for XX% off. Try it now: {promotional link}'
        ]
      },
      '<b>Use the promotional link provided by Aha</b>, not the official website link or a redirected link.'
    ],
    img: '/y-des-link.png'
  },
  {
    title: '',
    content: [
      "<b>Do not tag(@) the official brand's accounts</b> unless specified. This approach helps focus audience attention on the most crucial promotional links.",
      'Except for the legally required sponsorship disclosure in the description or caption, do not include any additional language or indications (e.g., "#ad" or "advertisement") that explicitly label the content as an ad.'
    ],
    img: '/y-des-tag.png'
  },
  // {
  //   title: '',
  //   content: [
  //     'Avoid mentioning "paid partnership", "ad" or similar terms in the description.'
  //   ],
  //   img: '/y-des-avoid.png'
  // },
  {
    title: 'Music and Effects',
    content: [
      'Check for copyright restrictions in your background music, sound effects, and clips.'
    ],
    img: ''
  }
];

export const tTRequiredContents: {
  title: string;
  content: string[];
  img: string;
}[] = [
  {
    title: 'Video content',
    content: [
      'Once the application is approved, please check out the Aha studio as a reference before starting the video production process.'
    ],
    img: '/aha-studio.png'
  },
  {
    title: 'Video length',
    content: [
      'Keep video length more than 20 seconds, optimized for quick consumption and high engagement.'
    ],
    img: '/20s.png'
  },
  {
    title: 'Ending CTA',
    content: [
      'Prompt viewers in speech and with caption at the end of the video, saying, “Tap the link in bio for more info!” and “Use the on-screen code {coupon code} for exclusive offers.” if coupon code is provided.'
    ],
    img: '/tt-ending-cta.png'
  },
  {
    title: 'Caption',
    content: [
      'Use a concise and engaging caption with a strong call-to-action. Example: “🌟 Grab XX% off with code {coupon code}! Check the bio link now! 🌟”'
    ],
    img: '/tt-caption.png'
  },
  {
    title: '',
    content: [
      'Include trending hashtags that are relevant to the content to increase discoverability. Please check keywords from Aha Studio for reference.'
    ],
    img: '/tt-caption-trending.png'
  },
  {
    title: '',
    content: [
      'Avoid explicit mentions of “ad” or “sponsored” in the caption unless legally required, though transparency is necessary if mandated by platform rules.'
    ],
    img: '/tt-caption-avoid.png'
  },
  {
    title: 'Link in Bio',
    content: [
      'Place the link at the top of your bio. If using Linktree, position it above Linktree or as the first Linktree link.',
      'Use the promotional link provided by Aha, not the official website link or a redirected link.'
    ],
    img: '/tt-link-in-bio.png'
  },
  {
    title: 'Music and audio',
    content: [
      'Use popular tracks from TikTok’s music library or original sounds that are trending to enhance viewer retention.',
      'Ensure any music or sound effects used are cleared for commercial use to avoid copyright issues.'
    ],
    img: ''
  }
];

export const InstagramRequiredContents: {
  title: string;
  content: string[];
  img: string;
}[] = [
  {
    title: 'Video content',
    content: [
      'Once the application is approved, please check out the Aha studio as a reference before starting the video production process.'
    ],
    img: '/aha-studio.png'
  },
  {
    title: 'Video length',
    content: [
      'Ensure the Reel is between 15-30 seconds for optimal engagement.'
    ],
    img: ''
  },
  {
    title: 'Ending CTA',
    content: [
      'Clearly state, “Swipe up for more info!” or “Check the link in bio!” depending on the features available.',
      'Display the on-screen coupon code for exclusive offers, if provided.'
    ],
    img: ''
  },
  {
    title: 'Caption',
    content: [
      'Begin with a strong Call-to-Action in the caption.',
      'Example: “Use code {coupon code} for XX% off! Visit the link in bio now!”',
      'Mention any relevant hashtags.',
      '@mentions only if required.',
      'Do not mention it as a paid partnership in the caption unless required by legal guidelines.'
    ],
    img: ''
  },
  {
    title: 'Music and Effects',
    content: [
      'Utilize copyright-free music or Instagram’s provided library to avoid copyright infringement.'
    ],
    img: ''
  }
];

export const TwitterRequiredContents: {
  title: string;
  content: string[];
  img: string;
}[] = [
  {
    title: 'Content Restrictions',
    content: [
      'We recommend avoiding political commentary, explicit sexual content, graphic violence, sensitive social issues, or misleading information in recent content, as these can negatively impact all brand collaborations.'
    ],
    img: ''
  },
  {
    title: 'Video Content',
    content: [
      'Once your application is approved, please select and closely imitate one of the “Hit Video Ideas” provided by Aha Studio. This step must be completed before initiating the video production process.',
      'Please add subtitles to the video.'
    ],
    img: '/aha-studio.png'
  },
  {
    title: 'Video length',
    content: ['Videos should be between 1-2 minutes long.'],
    img: ''
  },
  {
    title: 'Ending CTA',
    content: [
      'Integrate a clear verbal or text-based CTA within the video, such as “Visit the link in our tweet for more info and exclusive offers using {coupon code}.”',
      'Ensure the link is placed in a prominent position and remains visible without being folded or hidden.'
    ],
    img: ''
  },
  {
    title: 'Image-Based Content',
    content: [
      'Attach informative captions to each image, focusing on distinct features or benefits.',
      'Use high-resolution images, professionally captured to emphasize product details.'
    ],
    img: ''
  },
  {
    title: 'Tweet',
    content: [
      'No threads allowed.',
      'Start with a strong CTA: “Check out our latest video to discover amazing deals!  {link}”',
      'Use the promotional link provided by Aha, not the official website link or a redirected link.',
      'Use relevant hashtags to increase visibility.',
      'Avoid tagging the brand unless it is part of a collaboration effort.'
    ],
    img: ''
  },
  {
    title: 'Music and Sound',
    content: [
      'Voiceover is required in the video.',
      'Ensure all background music and sound effects are cleared for use to avoid copyright issues.'
    ],
    img: ''
  }
];

export const LinkedInRequiredContents: {
  title: string;
  content: string[];
  img: string;
}[] = [
  {
    title: 'Content Restrictions',
    content: [
      'We recommend avoiding political commentary, explicit sexual content, graphic violence, sensitive social issues, or misleading information in recent content, as these can negatively impact all brand collaborations.'
    ],
    img: ''
  },
  {
    title: 'Video Content',
    content: [
      'Once your application is approved, please select and closely imitate one of the “Hit Video Ideas” provided by Aha Studio. This step must be completed before initiating the video production process.',
      'Please add subtitles to the video.'
    ],
    img: '/aha-studio.png'
  },
  {
    title: 'Video length',
    content: ['Videos should be between 1-2 minutes long.'],
    img: ''
  },
  {
    title: 'Ending CTA',
    content: [
      'Integrate a clear verbal or text-based CTA within the video, such as “Visit the link in our tweet for more info and exclusive offers using {coupon code}.”',
      'Ensure the link is placed in the first line and remains visible without being folded or hidden.'
    ],
    img: ''
  },
  {
    title: 'Image-Based Content',
    content: [
      'Attach informative captions to each image, focusing on distinct features or benefits.',
      'Use high-resolution images, professionally captured to emphasize product details.'
    ],
    img: ''
  },
  {
    title: 'Post',
    content: [
      'No threads allowed.',
      'Start with a strong CTA: “Check out our latest video to discover amazing deals!  {link}”',
      'Use the promotional link provided by Aha, not the official website link or a redirected link.',
      'Use relevant hashtags to increase visibility.',
      'Avoid tagging the brand unless it is part of a brand requirement.',
      'Do not include any additional language or indications (e.g., "#ad" or "advertisement") in the caption that explicitly labels the content as an ad.'
    ],
    img: ''
  },
  {
    title: 'Music and Sound',
    content: [
      'Voiceover is required in the video.',
      'Ensure all background music and sound effects are cleared for use to avoid copyright issues.'
    ],
    img: ''
  }
];
export enum ProductType {
  WEB_APP = 'web app',
  MOBILE_APP = 'mobile app',
  DESKTOP_APP = 'desktop app',
  BROWSER_EXTENSION = 'browser extension',
  PHYSICAL_PRODUCT = 'physical product'
}

export enum ProductPhysicalSelectEnum {
  ship = 'Shipping',
  video = 'ShareVideo'
}
