import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import { Tracker } from '@/utils';
import { OrderStatus } from '@/interface/order';
import { useDetailDataStore } from '@/hooks/useDetailDataStore';
import { getOrderId, isMobile } from '@/biz/tool';
import WebModal from '@/components/web/components/Modal';
import { ProductType, ProductPhysicalSelectEnum } from '../config';
import { useWebModalStore } from '@/hooks/useWebModalStore';

const ModalJoinSuccess: React.FC<{}> = () => {
  const { detailData } = useDetailDataStore();
  const [isOpen, setIsOpen] = useState(false);
  const orderId = getOrderId();
  const { setShowAddressModal } = useWebModalStore();

  useEffect(() => {
    // 提醒观看Requirements
    if (
      ([OrderStatus.UnderProduction].includes(detailData?.order?.status) &&
        detailData?.order?.contentStage === 'Script') ||
      OrderStatus.PendingCreatorAddress === detailData?.order?.status
    ) {
      let contentRequirementsViewed;
      try {
        contentRequirementsViewed = window.localStorage.getItem(
          `content_requirements_viewed-${orderId}`
        );
      } catch (e) {}
      setIsOpen(contentRequirementsViewed ? false : true);
      // 上报 看过
      Tracker.click('content requirements tips');
    } else {
      // try {
      //   window.localStorage.removeItem(
      //     `content_requirements_viewed-${orderId}`
      //   );
      // } catch (e) {}
    }
  }, [detailData]);

  const handleClose = () => {
    setIsOpen(false);
    // 跳转到指定位置
    document.getElementById('content-requirements')?.scrollIntoView({
      behavior: 'smooth', // 实现平滑滚动
      block: 'start' // 滚动到元素顶部对齐
    });
    window.localStorage.setItem(
      `content_requirements_viewed-${orderId}`,
      'true'
    );
  };

  const isPhysicalProductShip =
    detailData?.product?.productType === ProductType.PHYSICAL_PRODUCT &&
    detailData?.product?.physicalProductShipType ===
      ProductPhysicalSelectEnum.ship;

  return (
    <>
      <WebModal
        visible={isOpen}
        isShowHeader={false}
        isShowFooter={false}
        width={isMobile() ? 342 : 624}
      >
        <div
          className={`w-full flex-col justify-start items-center gap-6 inline-flex`}
        >
          <img
            src="/modal-join-approved.png"
            alt="modal-join-approved"
            className="w-[257px] h-[128px]"
          />
          <div className="self-stretch flex-col justify-start items-center gap-8 flex">
            <div className="self-stretch  flex-col justify-start items-center gap-2 flex">
              <div
                className={`self-stretch text-center text-[#1c2024] text-xl font-semibold font-['Geist'] 
                  ${isMobile() ? '' : 'leading-loose'}`}
              >
                Woohoo! You’ve successfully joined this deal.
              </div>
              <div className="self-stretch text-center text-[#60646c] text-base font-normal font-['Inter'] leading-normal">
                Welcome to the{' '}
                <span className="text-[#1C2024] font-medium">
                  {detailData?.product?.name}
                </span>{' '}
                {isPhysicalProductShip
                  ? "collaboration! Before you start creating, let's take a moment to fill out your shipping address."
                  : "collaboration! Let's take a moment to review the content requirements before you start creating."}
              </div>
            </div>
            {isPhysicalProductShip ? (
              <div
                className={`${isMobile() ? 'flex flex-col gap-4' : 'flex gap-6'}`}
              >
                <Button
                  onClick={handleClose}
                  className={`mui-btn-gray !h-10
                    ${isMobile() ? '!w-[260px] ' : '!w-[268px] mb-4'}`}
                >
                  Maybe later
                </Button>
                <Button
                  className={`mui-btn-primary !h-10
                    ${isMobile() ? '!w-[260px] ' : '!w-[268px]'}`}
                  onClick={() => {
                    handleClose();
                    setShowAddressModal(true);
                  }}
                >
                  Enter shipping address
                </Button>
              </div>
            ) : (
              <Button
                onClick={handleClose}
                className={`mui-btn-primary !h-10
                  ${isMobile() ? '!w-[260px] ' : '!w-[400px]'}`}
              >
                View now
              </Button>
            )}
          </div>
        </div>
      </WebModal>
    </>
  );
};

export default ModalJoinSuccess;
