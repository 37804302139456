import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { isMobile } from '@/biz/tool';
import WebModal from './Modal';
import BottomDrawer from './BottomDrawer';
import { useWebModalStore } from '@/hooks/useWebModalStore';
import ModalAnalysisBreadcrumb from './ModalAnalysisBreadcrumb';
import InterestContent from './InterestContent';
import { useDetailDataStore } from '@/hooks/useDetailDataStore';
import { useMultiplePricesStore } from '@/hooks/useMultiplePricesStore';
import axiosInstance from '../../../apis/axiosInstance';
import { AuthorizeErrorIcon } from '@/components/common/icon';
import toast from 'react-hot-toast';
import Radio from '@/components/Radio/index';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useOrderListStore } from '@/hooks/useOrderListStore';

const ModalShowAnInterest: React.FC = () => {
  const { showModalSubmitInterest, setShowModalSubmitInterest } =
    useWebModalStore();

  return (
    <>
      {isMobile() ? (
        <BottomDrawer
          title="Submit interest"
          visible={showModalSubmitInterest}
          handleClose={() => setShowModalSubmitInterest(false)}
          isShowFooter={false}
          rootClassName="!z-[9999]"
        >
          <ModalContent />
        </BottomDrawer>
      ) : (
        <WebModal
          width={810}
          title="Submit interest"
          visible={showModalSubmitInterest}
          handleClose={() => setShowModalSubmitInterest(false)}
          isShowFooter={false}
        >
          <ModalContent />
        </WebModal>
      )}
    </>
  );
};

const ModalContent: React.FC = () => {
  const { duringFirstOrder, reQueryDetailData, detailData } =
    useDetailDataStore();
  const { reQueryOrderListData } = useOrderListStore();
  const { order, influencer, payment, timeline } = detailData;
  const {
    setShowModalSubmitInterest,
    setShowModalMissOut,
    setShowModalJoinSuccess,
    skipScript,
    isAgree,
    setIsAgree,
    isShowAgreement,
    setIsShowAgreement,
    setShowModalSelectInvitedOrderByNoMatch,
    setShowModalSelectInvitedOrderByNoMatchType
  } = useWebModalStore();
  const [joinBtnLoading, setJoinBtnLoading] = useState(false);
  const { selectedPriceFields, inputPrice } = useMultiplePricesStore();
  const [noticeAgree, setNoticeAgree] = useState(false);

  const joinCampaign = async () => {
    const orderId = order.id;
    const campaignId = order.campaignId;
    const platform = order.platform;
    setJoinBtnLoading(true);
    try {
      const params: any = {
        campaignId,
        platform,
        orderId
      };

      if (selectedPriceFields?.length > 0) {
        if (inputPrice) {
          params.interestedPriceFields = selectedPriceFields.map((el: any) => {
            return {
              ...el,
              price: Number(inputPrice)
            };
          });
        } else {
          params.interestedPriceFields = selectedPriceFields;
        }
      }
      if (skipScript) {
        params.skipScript = true;
      }

      const {
        data: { code, message }
      } = await axiosInstance.post(`/campaign/join`, params);
      toast.success('You have successfully applied!', { duration: 2000 });
      setShowModalSubmitInterest(false);

      // 请求 invited 订单
      setShowModalSelectInvitedOrderByNoMatchType('success');
      setShowModalSelectInvitedOrderByNoMatch(true);
      await reQueryOrderListData();
      // end 请求 invited 订单

      // if (duringFirstOrder) {
      //   // 首单 不走如下步骤
      //   return;
      // }
      if (payment) {
        reQueryDetailData({ orderId });
      } else {
        setTimeout(() => {
          window.location.href = `/campaign/wallet/${orderId}`;
        }, 2000);
      }
    } catch (error: any) {
      if (error.code === 40010) {
        // The campaign quota is full: Join quota is not enough
        toast.error(
          'We sincerely apologize, but this campaign has reached its capacity and no longer has available spots. Thank you for your understanding!'
        );
        reQueryDetailData({ orderId });
      } else {
        toast.error(error.message);
      }
      console.error(`Failed to fetch : (/campaign/join) \n`, error);
    } finally {
      setJoinBtnLoading(false);
    }
  };

  return (
    <div
      className={`w-full flex flex-col items-start gap-6
      ${isMobile() ? 'h-[calc(100vh)]' : ''}
    `}
    >
      <ModalAnalysisBreadcrumb index={2} />
      {noticeAgree ? (
        <div className="w-full h-12 px-2 py-3 bg-[#ffebe5] text-red-500 rounded-lg flex gap-2">
          <AuthorizeErrorIcon className="w-6 h-6" />
          Please agree to the Creator Agreement.
        </div>
      ) : null}
      <div
        className={`overflow-auto flex flex-col gap-6
          ${isMobile() && !noticeAgree ? 'h-[calc(100vh-210px)]  pb-10' : ''}
          ${isMobile() && noticeAgree ? 'h-[calc(100vh-290px)]  pb-10' : ''}
          `}
      >
        <InterestContent />
      </div>
      {/*  */}
      <div
        className={`bg-white w-full flex justify-end items-center gap-4 border-t border-solid border-[#e8e8ec] pt-4 mt-4
            ${isMobile() ? 'justify-center fixed bottom-0 left-0 pb-4' : ''}
          `}
      >
        <div className="flex flex-col gap-4">
          <div style={{ display: isMobile() ? 'flex' : 'none' }}>
            <Radio
              checked={isAgree}
              onClick={() => {
                setIsAgree(!isAgree);
              }}
            />
            <span className="ml-1">I consent to Aha's</span>
            <span
              className="flex"
              onClick={() => setIsShowAgreement(!isShowAgreement)}
            >
              Creator Agreement
            </span>
            .
          </div>
          <div className="flex gap-4">
            <Button
              className="mui-btn-gray !w-[160px] !h-10"
              onClick={() => {
                setShowModalSubmitInterest(false);
                setShowModalMissOut(true);
              }}
            >
              Cancel
            </Button>
            <Button
              className="mui-btn-primary !w-min-[160px] !h-10"
              onClick={() => {
                if (joinBtnLoading) {
                  return;
                }
                if (!isAgree) {
                  if (isMobile()) {
                    setNoticeAgree(true);
                  } else {
                    toast.error('Please agree to the Creator Agreement.');
                  }
                  return;
                } else {
                  setNoticeAgree(false);
                }
                joinCampaign();
              }}
            >
              {joinBtnLoading && (
                <CircularProgress
                  className="!w-6 !h-6 mr-1 "
                  disableShrink
                  color="inherit"
                />
              )}
              {joinBtnLoading ? 'Submitting interest...' : 'Submit interest'}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalShowAnInterest;
