import React from 'react';

import { useCountDown } from '../../../hooks/useCountdown';
import { SubmitStatusEnum } from '../config';

import styles from './countDown.module.less';

interface Props {
  timeline?: any;
  submitStatus?: string;
  isShowSeconds?: boolean;
}

const CountDown: React.FC<Props> = ({
  timeline,
  submitStatus,
  isShowSeconds = true
}) => {
  const draftDeadline = (createdAt?: string | Date | null) => {
    if (!createdAt) return;
    return new Date(createdAt).getTime();
  };

  let ddl: string | Date | null = null;
  if (submitStatus === SubmitStatusEnum.finalLink) {
    ddl = timeline?.finalPostDeadline?.deadlineTs;
  }
  if (submitStatus === SubmitStatusEnum.video) {
    ddl = timeline?.draft?.deadlineTs;
  }
  if (submitStatus === SubmitStatusEnum.script) {
    ddl = timeline?.initialScriptDraft?.deadlineTs;
  }
  if (submitStatus === SubmitStatusEnum.address) {
    ddl = timeline?.address?.deadlineTs;
  }

  const time = draftDeadline(ddl);
  const [day, hour, minute, second] = useCountDown(time);

  return (
    <div className={styles.container}>
      {!!day && <div className={styles.content}>{day}d</div>}
      {!!hour && <div className={styles.content}>{hour}h</div>}
      {!!minute && <div className={styles.content}>{minute}m</div>}
      {!!second && isShowSeconds && (
        <div className={styles.content}>{second}s</div>
      )}
      <div className={styles.text}>left to submit</div>
    </div>
  );
};

export default CountDown;
