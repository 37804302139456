import React, { useState, useEffect } from 'react';
import { LinkShareIcon, WarningIcon } from '@/components/common/icon';
import { normalizePrice } from '@/utils/normalizePrice';
import ProductLogo from '../ProductLogo';

import styles from './index.module.less';

interface Props {
  product: any;
}

const PhysicalProductShipInfo: React.FC<Props> = ({ product }) => {
  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <ProductLogo src={product?.physicalProductPhoto} />
        <div className={styles.cardRight}>
          <div className={styles.name}>{product?.physicalProductName}</div>
          <div className={styles.content}>
            <div className={styles.price}>
              {normalizePrice(product?.physicalProductCashValue)}
            </div>
            <div
              className={styles.link}
              onClick={() => {
                window.open(product?.physicalProductLink, '_blank');
              }}
            >
              <span>View product</span>
              <LinkShareIcon />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.notice}>
        <WarningIcon className={styles.warnIcon} />
        <span>
          Upon successful collaboration, you will be granted full usage rights
          to this product.
        </span>
      </div>
    </div>
  );
};

export default PhysicalProductShipInfo;
