export enum OrderStatus {
  Created = 'Created',
  Inviting = 'Inviting',
  Invited = 'Invited',
  CreatorRejected = 'Creator_rejected',
  CreatorApplied = 'Creator_applied',
  ApplyRejected = 'Apply_rejected',
  UnderProduction = 'Under_production',
  UnderReview = 'Under_review',
  ReviewRejected = 'Review_rejected',
  PendingPublish = 'Pending_publish',
  PublishRejected = 'Publish_rejected', // youtube 链接校验 失败。
  PendingPayment = 'Pending_payment',
  PaymentSubmitted = 'Payment_submitted',
  Paid = 'Paid',
  PaymentFailed = 'Payment_failed',
  Cancelled = 'Cancelled',
  Completed = 'Completed',
  UnderRevision = 'Under_revision', // Deprecated 若有未迁移的老数据, 还是需要展示
  PendingProductShipment = 'Pending_Product_shipment', // 待发货
  PendingProductArrival = 'Pending_Product_Arrival', // 发货中
  Pending = 'Pending', // 资源池
  PendingCreatorAddress = 'Pending_creator_address' // 待达人填写地址
}

export enum OrderContentType {
  // https://cxa8mlnnp7j.feishu.cn/wiki/C7AywMdMQiSfcokSbhCcRJ1knmx#YpH3dImSuoA9n3xHUdqcf3ECnud
  DEDICATED_VIDEO = 'Dedicated video',
  INTEGRATED_VIDEO = 'Integrated video',
  VIDEO = 'Video',
  POST = 'Post',
  REELS = 'Reels'
}

// https://cxa8mlnnp7j.feishu.cn/wiki/C7AywMdMQiSfcokSbhCcRJ1knmx#YpH3dImSuoA9n3xHUdqcf3ECnud
export enum OrderContentGenre {
  TUTORIAL = 'Tutorial',
  STORYTELLING = 'Storytelling',
  PRODUCT_DISPLAY = 'Product display',
  TEST_REVIEW = 'Test & review',
  SHOWCASE = 'Showcase',
  NEWS_UPDATE = 'News update'
}

export enum AdsPlatform {
  Youtube = 'youtube',
  TikTok = 'tiktok',
  Instagram = 'instagram',
  Facebook = 'facebook',
  LinkedIn = 'linkedin',
  X = 'x',
  Twitter = 'twitter'
}
