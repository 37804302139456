import React, { useState, useEffect } from 'react';
import { isMobile } from '@/biz/tool';
import Button from '@material-ui/core/Button';
import { useWebModalStore } from '@/hooks/useWebModalStore';
import CountDown from '../CountDown';
import { useDetailDataStore } from '@/hooks/useDetailDataStore';
import { OrderStatus } from '@/interface/order';
import { SubmitStatusEnum } from '../../config';

interface Props {}

const PhysicalShipStatus: React.FC<Props> = ({}) => {
  const { setShowAddressModal } = useWebModalStore();
  const { detailData } = useDetailDataStore();
  const { timeline, order } = detailData || {};
  if (
    order?.status !== OrderStatus.PendingCreatorAddress ||
    order?.creatorNextOperateType !== 'CreatorAddress'
  ) {
    return null;
  }
  return (
    <div
      className={`relative pb-6 bg-white rounded-2xl flex-col justify-start items-start gap-4 inline-flex
          ${isMobile() ? 'w-full p-4' : 'pt-8 px-6 w-[405px]'}
        `}
    >
      <div className="text-[#1c2024] text-[23px] font-semibold font-['Plus Jakarta Sans'] leading-9">
        Enter shipping address
      </div>
      <div className="text-[#1c2024] text-sm font-normal font-['Inter'] leading-snug">
        Please enter your address to receive your product directly from our
        brand. Rest assured, your address information is securely protected and
        will be used solely for shipping purposes.
      </div>
      <Button
        className="mui-btn-primary !w-full !h-10"
        onClick={() => {
          setShowAddressModal(true);
        }}
      >
        Enter shipping address
      </Button>
      <div className="flex justify-center w-full">
        <CountDown
          timeline={timeline}
          submitStatus={SubmitStatusEnum.address}
        />
      </div>
    </div>
  );
};

export default PhysicalShipStatus;
