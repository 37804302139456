import React, { useState, useEffect } from 'react';
import { getOrderId, isMobile } from '@/biz/tool';
import toast from 'react-hot-toast';
import WebModal from './Modal';
import BottomDrawer from './BottomDrawer';
import { Elements, AddressElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import appConfig from '@/utils/config';
import { useMultiplePricesStore } from '@/hooks/useMultiplePricesStore';
import { NationsLab } from '@/utils/locations';
import { useLoginStore } from '@/hooks/useLoginStore';
import { useDetailDataStore } from '@/hooks/useDetailDataStore';
import { useWebModalStore } from '@/hooks/useWebModalStore';
import axiosInstance from '@/apis/axiosInstance';

import styles from './ModalAddress.module.less';

const stripePromise = loadStripe(appConfig['stripe_public_key']);

interface Props {}

const ModalAddress: React.FC<Props> = ({}) => {
  const { detailData, reQueryDetailData } = useDetailDataStore();
  const { isShowAddressModal, setShowAddressModal } = useWebModalStore();

  const [complete, setComplete] = useState(false);
  const [address, setAddress] = useState(null);
  const orderId = getOrderId();

  const handleSubmit = async () => {
    try {
      await axiosInstance.post(`/order/${orderId}/address`, {
        addressInfo: address
      });
      reQueryDetailData({ orderId });
      toast.success('success!');
      setShowAddressModal(false);
    } catch (error) {
      toast.error('Submission failed.');
    }
  };

  // locations
  const handleAddressChange = (event: any) => {
    const { complete, value } = event;
    const locations = detailData?.campaign?.locations || [];
    const isInclude = locations?.some((item: string[]) =>
      item?.includes(value?.address?.country)
    );
    if (!isInclude) {
      toast.error(`
        We're sorry that your location is not meeting the brand's requirements. To begin this collaboration, please ensure that you are located in one of the following countries: 
        ${locations?.map((item: string[]) => NationsLab?.[item?.[1]]).join(', ')}.
        `);
      setComplete(false);
      return;
    }

    setComplete(complete);
    if (complete) {
      const { address, name, phone } = value;
      setAddress({
        ...address,
        name,
        phone
      });
    }
  };

  return (
    <>
      {isMobile() ? (
        <BottomDrawer
          title="Enter your address details"
          visible={isShowAddressModal}
          handleClose={() => setShowAddressModal(false)}
          handleSubmit={handleSubmit}
          submitDisabled={!complete}
          okText={'Continue'}
        >
          <ModalContent handleAddressChange={handleAddressChange} />
        </BottomDrawer>
      ) : (
        <WebModal
          title="Enter your address details"
          visible={isShowAddressModal}
          handleClose={() => setShowAddressModal(false)}
          handleSubmit={handleSubmit}
          submitDisabled={!complete}
          okText="Confirm"
        >
          <ModalContent handleAddressChange={handleAddressChange} />
        </WebModal>
      )}
    </>
  );
};

const ModalContent: React.FC<{
  handleAddressChange: any;
}> = ({ handleAddressChange }) => {
  const { userAddressInfo } = useLoginStore();

  const { name, phone, ...address } = userAddressInfo || {};
  return (
    <div className={isMobile() ? styles.mobileContent : styles.content}>
      <div className={styles.desc}>
        <span>
          Please enter your address to receive the product from the brand. Your
          address information is secure and protected, and it will only be used
          for the purpose of shipping the product to you. 
        </span>
      </div>
      <Elements stripe={stripePromise}>
        <AddressElement
          options={{
            mode: 'billing',
            fields: {
              phone: 'always'
            },
            validation: {
              phone: {
                required: 'always'
              }
            },
            defaultValues: {
              name,
              address,
              phone
            }
          }}
          onChange={handleAddressChange}
        />
      </Elements>
    </div>
  );
};

export default ModalAddress;
