import React, { useEffect, useState } from 'react';
import Tabs from '@/components/common/Tabs';
import NoticesBar from '@/components/web/NoticesBar';
import PhysicalProductNoticesBar from '@/components/web/components/PhysicalProductNoticesBar';
import DealStatus from './DealStatus';
import InfluencerAndTimeline from './InfluencerAndTimeline';
import ProductInfo from './ProductInfo';
import SubmitHistory from '@/components/web/components/submitHistory';
import { OrderStatus } from '../../interface/order';
import { isMobile } from '@/biz/tool';
import { useOrderIdStore } from '@/hooks/orderIdStore';
import { useDetailDataStore } from '@/hooks/useDetailDataStore';
import TooltipFirstOrder from './components/TooltipFirstOrder';
import ContentRequirements from './ContentRequirements';
import { TabEnum, ProductType, ProductPhysicalSelectEnum } from './config';
import ViralVideoRecommendation from './ViralVideoRecommendation';
import { useUser } from '@clerk/clerk-react';
import OneSignal from 'react-onesignal';
import ShippingDetail from './components/ShippingDetail';
import PhysicalShipStatus from './components/PhysicalShipStatus';

const DetailPageWeb: React.FC<{
  data: any;
  orderId: string;
  refreshAllData: Function;
}> = ({ data, orderId, refreshAllData }) => {
  const [tabId, setTabId] = useState(TabEnum.DealInfo);

  const { detailData, isLoadingData } = useDetailDataStore();
  const { user } = useUser();
  // 获取提交历史数据
  const [uploadedRecords, setUploadedRecords] = useState<any[]>([]);
  const [tabList, setTabList] = useState<any>([
    'Deal info',
    'Requirement',
    'Aha studio'
  ]);
  useEffect(() => {
    const orderDetail = detailData?.order;
    if (orderDetail) {
      const records = orderDetail?.contents || [];
      setUploadedRecords(records);
      const feedbackList = records.filter(
        (item: any) => item?.feedback && item?.feedbackAt
      );

      if (feedbackList?.length) {
        setTabId(TabEnum.Delivery);
        // 有红点
        if (records?.length) {
          setTabList(['Delivery', 'Deal info', 'Requirement', 'Aha studio']);
        }
      } else {
        // 无红点
        if (records?.length) {
          setTabList(['Deal info', 'Delivery', 'Requirement', 'Aha studio']);
        }
      }
    }
  }, [detailData]);

  useEffect(() => {
    OneSignal?.Slidedown?.promptPush()
      .then(() => {
        console.log('授权提示已显示');
      })
      .catch((error: any) => {
        console.error('无法显示授权提示:', error);
      });
  }, [user?.id]);

  const isShowDetailStatus = () => {
    return [
      OrderStatus.UnderProduction,
      OrderStatus.UnderReview,
      OrderStatus.ReviewRejected,
      OrderStatus.PendingPublish,
      OrderStatus.PublishRejected,
      OrderStatus.PendingPayment,
      OrderStatus.PaymentSubmitted
    ].includes(data?.order?.status);
  };

  const isShowNoticesBarOutDetailStatus = () => {
    // 反选通过前，在外部显示，
    return [
      OrderStatus.Invited,
      OrderStatus.CreatorApplied,
      OrderStatus.Cancelled
    ].includes(data?.order?.status);
  };

  const [isShowHistoryMark, setHistoryMark] = useState(false);
  const { orderIds, setOrderIds } = useOrderIdStore();

  useEffect(() => {
    const res = uploadedRecords.some((item) => {
      return (
        item?.feedback &&
        (!orderIds?.[orderId] ||
          !orderIds?.[orderId]?.historyList?.includes(item?.feedbackAt))
      );
    });
    setHistoryMark(res);
    if (res) {
      setTabId(TabEnum.Delivery);
    } else {
      setTabId(TabEnum.DealInfo);
    }
  }, [detailData, uploadedRecords, orderIds, orderId]);

  const handleRequirement = () => {
    setTabId(TabEnum.Requirement);
  };

  const handleTabChange = (event: any, newValue: TabEnum) => {
    if (isShowHistoryMark && newValue === TabEnum.Delivery) {
      const feedbackList = uploadedRecords.filter(
        (item) => item?.feedback && item?.feedbackAt
      );
      // 红点逻辑 增加缓存
      const historyList = feedbackList.map((item) => item?.feedbackAt);
      setOrderIds({
        ...orderIds,
        [orderId]: {
          ...orderIds?.[orderId],
          historyList
        }
      });
    }
    setTimeout(() => {
      setTabId(newValue);
    }, 100);
  };
  return (
    <div
      className={`bg-[#F0F0F3]  text-[#1c2024]
            ${isMobile() ? 'w-screen' : 'min-w-[1000px]'}`}
    >
      <div
        className={`mx-auto flex flex-col items-center 
            ${isMobile() ? 'w-screen px-4 app-detail-bg pt-8 gap-4 min-h-[calc(100vh-64px)]' : 'max-w-[1440px] px-20 pt-8 gap-8  min-h-[calc(100vh-80px)]'}`}
      >
        {/* 实体邮寄的提醒 */}
        {data?.product?.productType === ProductType.PHYSICAL_PRODUCT && (
          <PhysicalProductNoticesBar />
        )}
        {((isMobile() && isShowNoticesBarOutDetailStatus()) || !isMobile()) && (
          <NoticesBar />
        )}
        <div
          className={`w-full flex items-start justify-start 
          ${isMobile() ? 'flex-col-reverse gap-4' : 'gap-8'}`}
        >
          <div
            className={`relative w-full flex-1 bg-white rounded-2xl
                        ${isMobile() ? 'p-4 flex flex-col gap-6 mb-24' : 'gap-8 max-w-[66%]'}
            `}
          >
            <div className={isMobile() ? 'relative' : 'px-8 pt-4'}>
              {/* 首单提示 */}
              {isMobile() && <TooltipFirstOrder />}
              <Tabs
                tabId={tabId}
                handleChange={handleTabChange}
                tabItems={tabList}
                isShowHistoryMark={isShowHistoryMark}
                isShowMarkIndex={0}
              />
            </div>
            {tabId === TabEnum.DealInfo && <ProductInfo data={data} />}
            {tabId === TabEnum.Delivery && !!uploadedRecords.length && (
              <SubmitHistory orderDetail={detailData?.order} />
            )}
            {tabId === TabEnum.Requirement && (
              <ContentRequirements
                platform={detailData?.order?.platform}
                data={data}
              />
            )}
            {tabId === TabEnum.AhaStudio && (
              <ViralVideoRecommendation data={data} />
            )}
          </div>
          <div
            className={` flex flex-col items-center gap-8  top-28
              ${isMobile() ? 'w-full' : 'sticky w-[405px]'}`}
          >
            {isShowDetailStatus() && (
              <DealStatus
                data={data}
                refreshAllData={refreshAllData}
                handleRequirement={handleRequirement}
              />
            )}
            {/* 实体产品待填写地址状态展示 */}
            <PhysicalShipStatus />
            {!isMobile() && <InfluencerAndTimeline data={data} />}
            {/* 实体产品达人填写的地址信息展示 */}
            {data?.product?.productType === ProductType.PHYSICAL_PRODUCT &&
              data?.product?.physicalProductShipType ===
                ProductPhysicalSelectEnum.ship && (
                <ShippingDetail data={data} />
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailPageWeb;
